.pltr-panel > .pltr-header {
    padding: 6px 12px;
    /*margin: 0px 0 15px 0px;*/
    /*flex-shrink: 0;
    flex-grow: 0;*/
    border-bottom: solid rgb(243, 243, 243) 1px;
    box-shadow: 0 0.5px 4.2px 0 rgb(0 0 0 / 3%);
    /*align-items: center;*/
}

.pltr-panel {
    border: solid 1px rgba(212, 212, 212, 0.3);
    background-color: #FFFFFF;
    border-radius: 4px;
    flex-grow: 1;
}