.form-instance2 > .header2 {
    padding: 6px 12px;
    /*margin: 0px 0 15px 0px;*/
    /*flex-shrink: 0;
    flex-grow: 0;*/
    border-bottom: solid rgb(243, 243, 243) 1px;
    box-shadow: 0 0.5px 4.2px 0 rgb(0 0 0 / 3%);
    /*align-items: center;*/
}

.form-instance2 {
    border: solid 1px rgba(212, 212, 212, 0.3);
    background-color: #FFFFFF;
    border-radius: 4px;
    flex-grow: 1;
}

.form-instance2 > .body {
    /*height: 100%;*/
}
.form-instance2 > .body:not(.no-pad) {
    padding: 0px 20px 25px 20px;
}

.form-instance2 > .body:not(.no-pad) .body-lining {
    padding-top: 10px;
}
.form-instance2 > .body.wrap {
    flex-wrap: wrap;
}
.form-instance2 > .body.wrap > * {
    margin-bottom: 20px;
}
.form-instance2 > .footer {
    padding: 6px 12px;
    flex-shrink: 0;
    flex-grow: 0;
    border-top: solid 1px rgb(212, 212, 212);
    box-shadow: 0 1.2px 7.2px 0 rgb(0 0 0 / 13%);
}

.m-bot-20 {
    margin-bottom: 20px;
}


.pltr-card.basic {
    border: solid 1px rgb(212, 212, 212);
    background-color: #FFFFFF;
    border-radius: 4px;
    flex-grow: 1;
    padding: 10px;
}
